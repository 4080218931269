.changePassword {
  display: flex;
  gap: 16px;
  flex-direction: column;
}

.password_specification {
  display: flex;
  align-items: flex-start;
  gap: 8px 36px;
  flex-wrap: wrap;
}

.password_rule {
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 128px;
}
