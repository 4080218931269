@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('./components/slider.css');
@import url('./components/default-loader.css');
@import url('./components/quick-search.css');
@import url('./components/modal.css');
@import url('~@lululemon/ecom-pattern-library/lib/styles/fonts.css');
@import url('~@lululemon/ecom-pattern-library/lib/styles/base.css');
@import url('./components/my-account.css');
@import url('./components/account.css');
@import url('./atoms/input-new-password.css');
@import url('./components/payment.css');
@import url('~react-dates/lib/css/_datepicker.css');
body {
  width: 100%;

  @apply font-body;
  @apply text-primary-black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-heading;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  @apply underline-offset-[0.25em];
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

input:focus {
  box-shadow: none !important;
}

input[type='search']::-webkit-search-cancel-button {
  display: none;
}

@layer utilities {
  .text-shadow {
    text-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
  }
}

/* content max-width to match B2C https://github.com/Lululemon/ecom-web-app/blob/main/packages/ecom-common/src/style/util/variables.scss#L27 */

.main-content-wrapper {
  max-width: 1440px;
}
