@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

.overlay,
.overlayBlur {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 150;
  background-color: rgba($black, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlayBlur {
  background-color: transparent;
  backdrop-filter: blur(1px);
}
