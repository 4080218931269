#price-component .price {
  display: block;
  font-size: rem(19);
  font-weight: 300;

  @include mq($desktop-and-above) {
    font-size: rem(20.8);
  }

  &__currency-code {
    @include currencyType;
  }

  &__inactive-list-price {
    color: $grey65;
    display: inline;
    margin-left: rem(5);
    overflow: hidden;
    position: relative;

    &::before {
      border-bottom: solid rem(2) $grey65;
      content: '';
      position: absolute;
      top: 50%;
      width: 100%;
    }
  }
}
