@mixin currencyType {
  font-size: rem(12);
  font-weight: 500;
  letter-spacing: rem(1.3);
  line-height: 1.2;

  @include mq($desktop-and-above) {
    font-size: rem(14);
  }
}
