.my-account-container {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  margin-top: 12px;
  align-self: stretch;
  border-radius: 2px;
  border: 1px solid var(--border-subdued-grey-25, #d3d5d7);
  background: #fff;
}
.my-account-container-cell {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
}

.my-account-container-cell-breaker {
  background-color: #d9d9d9;
  height: 1px;
}
