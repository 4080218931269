@import '~@lululemon/ecom-pattern-library/lib/styles/includes.scss';

@import './util/mixins.scss';
@import './components/price.scss';

body,
html {
  background-color: $grey03;
  color: $black;
}

::selection {
  background: $black;
  color: $white;
}
